import React, { ReactNode } from 'react'
import Box from './primitives/Box'
import Button from './system/Button'

type Props = {
  title: ReactNode
  text: ReactNode
  buttonText?: ReactNode
  buttonUrl?: string
}

export const BlogCta = ({
  title,
  text,
  buttonText = 'Get a demo',
  buttonUrl = '/demo/',
}: Props) => {
  return (
    <Box
      borderRadius={12}
      padding={48}
      css={`
        background: rgba(63, 47, 237, 0.1);
      `}
    >
      <Box font="blogH2" color="black" fontWeight={700} paddingBottom={16}>
        {title}
      </Box>
      <Box font="blogText" color="black" paddingBottom={32}>
        {text}
      </Box>
      <Button
        href={buttonUrl}
        variant="primary"
        rightCaret
        size="large"
        color="white"
        minWidth={320}
        maxWidth="100%"
      >
        {buttonText}
      </Button>
    </Box>
  )
}

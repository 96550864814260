import { graphql } from 'gatsby'
import React, { useMemo, useState } from 'react'
import MetaImage from '../assets/images/changelog-meta.png'
import backgroundImage from '../assets/images/changelog/hero-bg.svg'
import tag from '../assets/images/changelog/tag.svg'
import ChangelogEntry from '../components/ChangelogEntry'
import EmailInputCTA from '../components/EmailInputCTA'
import Layout from '../components/Layout'
import HeroBlock from '../components/blocks/HeroBlock'
import Block from '../components/primitives/Block'
import Box from '../components/primitives/Box'
import Button from '../components/system/Button'
import { svgToImage } from '../styles/helpers'

const Changelog = ({ data }) => {
  const {
    allMdx: { edges },
  } = data

  const [showMore, setShowMore] = useState(false)
  const showNode = useMemo(() => getShowNode(showMore), [showMore])

  return (
    <Layout
      justifyCenter
      title="Changelog"
      description="We’re constantly building new features to Swarmia based on customer feedback. The changelog is where you’ll find all the recent product updates and improvements."
      metaImage={MetaImage}
      isNew
      variant="dark"
    >
      <HeroBlock
        tag={tag}
        title="Product updates"
        content="Subscribe to our newsletter to get the latest product updates to your inbox once a month."
        backgroundImage={svgToImage(backgroundImage, '#101010')}
        customCta={<HeroCTA />}
      />
      <Block paddingTop={92}>
        <Box maxWidth="smallBlock" marginX="auto">
          {edges.map(({ node }) => {
            return (
              <Box
                display={showNode(node) ? 'block' : 'none'}
                key={node.frontmatter.slug}
                paddingBottom={48}
              >
                <ChangelogEntry entry={node} />
              </Box>
            )
          })}
        </Box>
        <Box display="flex" justifyContent="center" marginBottom={64}>
          <Button
            variant="primary"
            size="large"
            onClick={() => setShowMore(!showMore)}
            width="100%"
            maxWidth={320}
          >
            {showMore ? 'Show fewer updates' : 'Show more updates'}
          </Button>
        </Box>
      </Block>
    </Layout>
  )
}

type FilterableNode = {
  frontmatter: {
    date: string
  }
}

const dateCutoff = new Date('2023-01-01')
function getShowNode(showMore: boolean) {
  if (showMore) {
    return () => true
  } else {
    return (node: FilterableNode) => {
      const date = new Date(node.frontmatter.date)
      return date.getTime() >= dateCutoff.getTime()
    }
  }
}

export default Changelog

export const pageQuery = graphql`
  query {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/src/changelog/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      ...ChangelogPostsFragment
    }
  }

  fragment ChangelogPostsFragment on MdxConnection {
    edges {
      node {
        body
        id
        frontmatter {
          date(formatString: "MMM D, YYYY")
          slug
          title
          meta {
            image {
              w640: childImageSharp {
                gatsbyImageData(width: 640, quality: 90)
                original {
                  src
                  width
                  height
                }
              }
              w1000: childImageSharp {
                gatsbyImageData(width: 1000, quality: 90)
                original {
                  src
                  width
                  height
                }
              }
            }
            imageAlt
          }
        }
      }
    }
  }
`

const HeroCTA = () => {
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const contentAfterSubmit = (
    <>
      <Box.span fontWeight={700}>You&apos;re in!</Box.span> From now on,
      you&apos;ll get our monthly newsletter delivered to your inbox.
    </>
  )

  return (
    <Box paddingTop={32} maxWidth={isSubmitted ? 480 : 540} width="100%">
      <EmailInputCTA
        hubspotFormId="d2fbe13f-3c80-458a-84ee-c46036dea41b"
        buttonContent="Subscribe"
        onSubmit={() => setIsSubmitted(true)}
        isSubmitted={isSubmitted}
        contentAfterSubmit={contentAfterSubmit}
        emojiAfterSubmit="🎉"
      />
    </Box>
  )
}

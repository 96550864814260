import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import { SpaceScaleValue } from '../../styles/stylePropTypes'
import theme from '../../styles/theme'
import Box from '../primitives/Box'

export function CoverImage({
  image,
  alt,
  bleed = true,
}: {
  image: IGatsbyImageData
  alt?: string
  bleed?: boolean
}) {
  return (
    <Box
      marginX={{
        xs: 0,
        md: bleed ? (-100 as SpaceScaleValue) : 0,
        lg: bleed ? (-190 as SpaceScaleValue) : 0,
      }}
      css={`
        .gatsby-image-wrapper {
          border-radius: 32px;
        }

        // This is when the sidebar layout collapses
        @media screen and (max-width: ${theme.breakpoints.lg}) {
          .gatsby-image-wrapper {
            border-radius: 12px;
          }
        }
      `}
    >
      <GatsbyImage image={image} alt={alt ?? ''} />
    </Box>
  )
}

import React, { ReactNode } from 'react'
import { css } from 'styled-components'
import { ThemeFontSize } from '../../styles/theme'
import Box from '../primitives/Box'
import Row from '../primitives/Row'
import Stack from '../primitives/Stack'

type Props = {
  quote: ReactNode
  name?: string
  title?: string
  photo?: string
}

export function InlineQuote(props: Props) {
  return (
    <Row marginY={48} marginLeft={32} marginRight={48} alignItems="stretch">
      <Box
        backgroundColor="quoteBar"
        width={8}
        flexShrink={0}
        css={{
          borderRadius: 2,
        }}
      />
      <Stack css={{ marginLeft: 28 }}>
        <Box.blockquote
          fontWeight={500}
          fontFamily="FactorA"
          fontSize={21 as ThemeFontSize}
          lineHeight="140%"
          css={css`
            &::before {
              content: '“';
              width: 10px;
              margin-left: -10px;
              display: inline-block;
            }
            &::after {
              content: '”';
              width: 10px;
              /* Prevent flowing to second row*/
              margin-right: -10px;
              display: inline-block;
            }
            p {
              display: inline;
            }
          `}
        >
          {props.quote}
        </Box.blockquote>
        {props.name ? (
          <Row marginTop={16} space={16} alignItems="center">
            {props.photo && <Box.img src={props.photo} width={64} />}
            <Box font="small" css={{ lineHeight: '160%' }}>
              {props.name}
              <Box.br />
              {props.title}
            </Box>
          </Row>
        ) : null}
      </Stack>
    </Row>
  )
}
